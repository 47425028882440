import React from 'react';
import { Col, Input, Row } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import { RightOutlined } from '@ant-design/icons';

import { Container } from "../Container/Container";
// import { FooterMenu } from "./FooterMenu/FooterMenu";

import logo from "../../assets/home/Logonm.svg";

import "./AppFooter.less";

export const AppFooter = () => {


  return (
    <Footer className="app-footer">
      <Container className="app-footer__content">
        <Row >
          <Col span={12} className="app-footer__logo">
            <img src={logo} alt={logo} />
          </Col>
         
          <Col span={12}>
            <div className="app-footer__subscribe">
              <h2 className="app-footer__subscribe-title">Subscribe Now</h2>
              <Input
                className="app-footer__subscribe-input"
                addonAfter={<RightOutlined />}
                placeholder="Email"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="app-footer__copyright">
        <span>
          Developed by
        <span> | </span>
          <a href="https://peter-hassaballah.netlify.app"> Out Of The Box solutions </a>
        </span>
      </Container>
    </Footer>
  )
}
