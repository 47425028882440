import React from 'react';
import { Affix, Menu,Row,Col } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { MenuOutlined } from '@ant-design/icons';
// import { Container } from '../Container/Container';
import "./AppHeader.less"
import logo from '../../assets/home/logomini.svg';

export const AppHeader = () => {
  const { Item } = Menu;

  return (
    <Affix offsetTop={0}>
      <Header className="app-header">
        <Row className="app-header__content">
          <Col span={4}>
            <img
              className="app-header__logo"
              src={logo}
              alt="logo"
            />
          </Col>
          <Col span={20}>
          <Menu
            className="app-header__menu"
            mode="horizontal"
            defaultSelectedKeys={["home"]}
            style={{paddingLeft:'100px'}}
            overflowedIndicator={<MenuOutlined className="app-header__menu-icon" />}
          >
            <Item key="home">
              <a className="app-header__menu-item" href="#home">Home</a>
            </Item>
            <Item key="about">
              <a className="app-header__menu-item" href="#about">About</a>
            </Item>
            <Item key="services">
              <a className="app-header__menu-item" href="#services">Services</a>
            </Item>
            <Item key="showcase">
              <a className="app-header__menu-item" href="#showcase">Showcase</a>
            </Item>
            {/* <Item key="pricing">
              <a className="app-header__menu-item" href="#pricing">Pricing</a>
            </Item> */}
            <Item key="blog">
              <a className="app-header__menu-item" href="#blog">Blog</a>
            </Item>
            <Item key="contact">
              <a className="app-header__menu-item" href="#contact">Contact</a>
            </Item>
            <Item Key="toggle">EN | NL
            </Item>
          </Menu>
          </Col>
          
        </Row>
      </Header>
    </Affix >
  )
}