import React from 'react'
import { Row, Col, Button, Space } from 'antd';
import "./Home.less";
import { Container } from '../../components/Container/Container';
import { AppButton } from '../../components/AppButton/AppButton';

import logo from '../../assets/home/Logonm.svg'
export const Home = () => {
  return (
    <section id="home" className="home">
      <Container className="home__container">
        <Row>
          <Col xs={24} md={12} className="home__intro">
            <h1 className="intro__heading">
              SEO Services for web masters
            </h1>
            <p className="intro__sub-heading">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab
              dolores ea fugiat nesciunt quisquam.
            </p>
            <Space size={"large"} className="intro__button-group">
              <Button size="large" style={{height:'50px'}} type="primary">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/">
                  Download Portfolio
                </a>
              </Button>
              <AppButton>
                <a href="#contact">
                  Contact US
                </a>
              </AppButton>
            </Space >
          </Col>
          <Col xs={24} md={12} className="home__img-wrapper">
            <img
              className="home__img"
              src={logo}
              alt={logo}
            />
          </Col>
        </Row>
      </Container>
    </section >
  )
}
